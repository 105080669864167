import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Layout } from '../../layouts'
import Seo from '../../components/seo'
import { INTRO } from '../../content'
import SiteTitle from '../../components/Blocks/SiteTitle/SiteTitle'
import { CONTENT } from '../../content'
import * as styles from './index.module.scss'

const Index = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "coral.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Emilie Pfister - Présentation" description={INTRO[1]} />
      <SiteTitle tag="span" />

      <div className="wrapper">
        <BackgroundImage
          className={styles.Presentation}
          Tag="section"
          fluid={image?.sharp?.fluid}
          style={{ backgroundSize: 'contain' }}
          id="presentation"
        />
        <h1>{CONTENT.WHOAMI.title}</h1>

        {CONTENT.WHOAMI.content.map((content, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
        ))}
      </div>
    </Layout>
  )
}

export default Index
